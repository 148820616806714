import { types } from '/@vuex/types'
import { mapGetters } from 'vuex'
import { empty } from '/@shared/utils'

export default {
  methods: {
    motorcortexUpdateAttribute(key, value) {
      this.$store.commit(types.MOTORCORTEX_ATTRIBUTE_UPDATE, {
        key,
        value,
      })
    },
    motorcortexGetTreeNodeFor(path) {
      let result = null
      const tree = this.$communication.getParameterTree()

      if (!empty(tree)) {
        try {
          result = tree.getChild(path)
        } catch (e) {
          result = null
        }
      }

      return result
    },
  },
  computed: {
    ...mapGetters({
      motorcortex: types.MOTORCORTEX_STATE,
      motorcortexAttribute: types.MOTORCORTEX_ATTRIBUTE,
    }),
    isMotorcortexConnected() {
      return this.$store.getters[types.MOTORCORTEX_ATTRIBUTE]('connection.open')
    },
  },
}
