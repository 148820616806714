export const types = {
  LOAD_GRID: 'LOAD_GRID',
  GRID: 'GRID',

  SETTINGS_LOAD: 'SETTINGS_LOAD',
  SETTINGS_STATE: 'SETTINGS_STATE',
  SETTINGS_ATTRIBUTE: 'SETTINGS_ATTRIBUTE',
  SETTINGS_ATTRIBUTE_UPDATE: 'SETTINGS_ATTRIBUTE_UPDATE',

  ARTBOARDS_LOAD: 'ARTBOARDS_LOAD',
  ARTBOARDS_STATE: 'ARTBOARDS_STATE',
  ARTBOARDS_ATTRIBUTE: 'ARTBOARDS_ATTRIBUTE',
  ARTBOARDS_ATTRIBUTE_UPDATE: 'ARTBOARDS_ATTRIBUTE_UPDATE',
  ARTBOARDS_ADD: 'ARTBOARDS_ADD',
  ARTBOARDS_ORDER: 'ARTBOARDS_ORDER',
  ARTBOARDS_DELETE: 'ARTBOARDS_DELETE',
  ARTBOARDS_COMPONENTS_PASTE: 'ARTBOARDS_COMPONENTS_PASTE',
  ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE: 'ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE',
  ARTBOARD_ACTIVE_MODELS: 'ARTBOARD_ACTIVE_MODELS',

  ARTBOARDS_MODEL_PROGRAM: 'ARTBOARDS_MODEL_PROGRAM',
  ARTBOARDS_MODEL_GEOMETRY: 'ARTBOARDS_MODEL_GEOMETRY',

  MODEL_UPDATE: 'MODEL_UPDATE',
  MODELS_UPDATE: 'MODELS_UPDATE',
  MODEL_ADD: 'MODEL_ADD',
  MODEL_DELETE: 'MODEL_DELETE',
  MODEL_ACTIVATE: 'MODEL_ACTIVATE',

  RASTER_LOAD: 'RASTER_LOAD',
  RASTER_STATE: 'RASTER_STATE',
  RASTER_ATTRIBUTE: 'RASTER_ATTRIBUTE',
  RASTER_ATTRIBUTE_UPDATE: 'RASTER_ATTRIBUTE_UPDATE',

  MOTORCORTEX_LOAD: 'MOTORCORTEX_LOAD',
  MOTORCORTEX_STATE: 'MOTORCORTEX_STATE',
  MOTORCORTEX_ATTRIBUTE: 'MOTORCORTEX_ATTRIBUTE',
  MOTORCORTEX_ATTRIBUTE_UPDATE: 'MOTORCORTEX_ATTRIBUTE_UPDATE',
  MOTORCORTEX_ATTRIBUTE_REALTIME_UPDATE: 'MOTORCORTEX_ATTRIBUTE_REALTIME_UPDATE',

  GRID_LOAD: 'GRID_LOAD',
  GRID_STATE: 'GRID_STATE',
  GRID_ATTRIBUTE: 'GRID_ATTRIBUTE',
  GRID_ATTRIBUTE_UPDATE: 'GRID_ATTRIBUTE_UPDATE',
  GRID_MANUAL_SAVE: 'GRID_MANUAL_SAVE',

  PROGRAMS: 'PROGRAMS',
  PROGRAMS_UPDATE: 'PROGRAMS_UPDATE',
}

export const undoMutations = [
  types.ARTBOARDS_LOAD,
  types.ARTBOARDS_ATTRIBUTE_UPDATE,
  types.ARTBOARDS_ADD,
  types.ARTBOARDS_ORDER,
  types.ARTBOARDS_DELETE,
  types.ARTBOARDS_COMPONENTS_PASTE,
  types.ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE,
  types.MODEL_UPDATE,
  types.MODELS_UPDATE,
  types.MODEL_ADD,
  types.MODEL_DELETE,
]

export const hookMutations = [
  types.GRID_MANUAL_SAVE,
  types.SETTINGS_ATTRIBUTE_UPDATE,
  types.ARTBOARDS_ATTRIBUTE_UPDATE,
  types.RASTER_ATTRIBUTE_UPDATE,
  types.GRID_ATTRIBUTE_UPDATE,
  types.GRID_MANUAL_SAVE,
  types.MOTORCORTEX_ATTRIBUTE_UPDATE,
  types.ARTBOARDS_ADD,
  types.ARTBOARDS_ORDER,
  types.ARTBOARDS_DELETE,
  types.ARTBOARDS_COMPONENTS_PASTE,
  types.ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE,
  types.MODEL_UPDATE,
  types.MODELS_UPDATE,
  types.MODEL_ADD,
  types.MODEL_DELETE,
]
